@import "./src/assets/scss/prepends.scss";


.works-detail-page #app{
  @include for-sp(){
    width: 100%;
    height: 100%;
  }
}

.detail {
  @include for-sp(){
    height: 100%;
  }
  &__body {
    box-sizing: border-box;
    padding: calc(140/1920 * 100vw) 0; 
    height: 100vh;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    @include for-sp(){
      padding: 0;
      margin: 0 0 0 calc(100/750 * 100vw);
      position: relative;
      height: 100%;
    }
  }
  &__img {
    width: 70vw;
    height: 90vh;
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    @include for-sp(){
      width: 100%;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }
    img {
      // clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0 100%);
      object-fit: contain;
      width: 100%;
      height: 100%;
      transform: translate(0, calc(20/1920 * 100vw));
      opacity: 0;
    }
  }
  &__txtbox{
    position: absolute;
    bottom: calc(60/1920 * 100vw);
    left: calc(140/1920 * 100vw);
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0 100%);
    @include for-sp(){
      bottom: auto;
      top: 0;
      right: 0;
      left: auto;
      display: flex;
      flex-flow: column wrap;
      align-items: flex-end;
    }
    .date {
      font-size: calc(25/1920 * 100vw);
      @include font-en-ttl;
      color: $white;
      line-height: 1;
      background-color: $black;
      width: max-content;
      padding: calc(3/1920 * 100vw);
      @include for-sp(){
        font-size: calc(20/750 * 100vw);
        padding: calc(5/750 * 100vw);
      }
    }
    .ttl {
      font-size: calc(60/1920 * 100vw);
      color: $white;
      width: max-content;
      line-height: 1;
      background-color: $black;
      padding: calc(3/1920 * 100vw);
      @include for-sp(){
        font-size: calc(50/750 * 100vw);
      }
    }
  }
  &__numbox {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__btn-list {
  }
  &__btn {
    position: absolute;
    z-index: 10;
    &--prev {
      top: 50%;
      transform: translate(0, -50%);
      left: calc(60/1920 * 100vw);
      @include for-sp(){
        top: auto;
        transform: translate(0, 0);
        bottom: calc(20/750 * 100vw);
        // left: calc(50vw - 90/750 * 100vw);
        left: auto;
        right: calc(220/750 * 100vw);
      }
    }
    &--next {
      top: 50%;
      transform: translate(0, -50%);
      right: calc(60/1920 * 100vw);
      @include for-sp(){
        top: auto;
        right: calc(130/750 * 100vw);
        transform: translate(0, 0);
        bottom: calc(20/750 * 100vw);
        // left: calc(50vw + 20/750 * 100vw);
      }
    }
  }
}

/* is loaded */
.is--loaded {
  .detail__img {
    img {
      // transition: clip-path 500ms cubic-bezier(0.54, 0.005, 0.155, 0.99);
      // clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 100%);
      opacity: 1;
      transition: all 500ms cubic-bezier(0.54, 0.005, 0.155, 0.99);
      transform: translate(0, 0);
    }
  }
  .detail__txtbox {
    transition: clip-path 500ms cubic-bezier(0.54, 0.005, 0.155, 0.99);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 100%);
  }
}

/* transition */
.v-enter-active, .v-leave-active {
  transition: all 700ms cubic-bezier(.4,.64,.68,1);
}
.v-enter-from {
  opacity: 0;
} 
.v-enter-to {
} 

.v-leave-from {
}
.v-leave-to {
  opacity: 0;
}

.is--loaded {
}

